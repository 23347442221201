.datepicker{
    display: flex;
}
.reportrange{
    display: flex;
    font-size: 11px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}