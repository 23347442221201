.dashboardcontainer{
    width: 100%;
    margin-top: 20px;
}
.buttongroup{
    margin-top: 20px;
    margin-left: 20px;
}
.filtersection{
    width: 97%;
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 10px;
}
.filterevents{
    width: 100%;
    height: 70px;
    display: grid;
}
.filterbar{
    display: flex;
}

.filtermodal{
    width: 500px;
    height: 400px;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 60px;
    box-shadow: 0 0 10px 1px rgba(29,29,29,.2);
}

.eventSearch{
    width: 100%;
    height: 50px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventSearch input{
    width: 90%;
    margin-top: 10px;
    border-radius: 5px;
    height: 40px;
}

.events{
    width:500px;
    height: 340px;
    border-radius: 10px;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x:hidden;
    display: flex;
    flex-direction: column;
    
}

.eventname{
    width: 90%;
    margin: auto;
    height: 30px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #FFF;
    color: #000;
}

.eventbutton{
    margin-top: 15px;
    
}


.eventsdropdown{
    margin-top: 80px;
    position: relative;
    height: 400px;
    overflow: hidden;
}



.events_section{
    margin-top: 30px !important;
    width: 97%;
    margin: auto;
}

.accordation{
    display: flex;
    justify-content: space-between;
}

.accordationtext{
    width: 100%;
    display: flex;
    font-size: 14px !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    align-items: center;
    justify-content: center;    
    flex-shrink: 0;
}

.eventheadernames{
    display: flex;
    width: 98%;
    margin-left: 27px;
    justify-content: space-between;
}
.eventheadername{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payloadkey{
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: capitalize;
}

.datepicker{
   width: 300px;
   margin-left: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}
.reportrange{
    width: 250px !important;
    height: 30px !important;
    align-items: center;
    background: #1976D2 !important;
    border: none !important;
    display: flex;
    color: #FFF;
    justify-content: center;
    border-radius: 5px;
}