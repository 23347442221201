.graph_container {
    display: flex;
    gap: 4px;
    align-items: flex-end;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.bar {
    display: inline-block;
    width: 20px;
    background:rgba(32, 89, 164, 0.734);
    opacity: 0.5;
}


