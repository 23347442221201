
.filtersection{
    width: max-content;
    margin: auto;
    display: flex;
    align-items: center;
    margin-top: 20px !important;
    gap: 40px;
}
.filter{
 display: block;    
}

.overallperformancesection{
    width: 95%;
    margin-top: 50px;
}

.overallperformancesection h1{
    font-size: 30px;
    margin-bottom: 30px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.0075em;
    opacity: 1;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    color: rgb(52, 71, 103);
    font-weight: 600;
}

.overallsection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.troopodABsection{
    width: 95%;
    margin: auto;
    margin-top: 50px !important;   
}

.troopodABsection h1{
    font-size: 30px;
    margin-bottom: 30px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.0075em;
    opacity: 1;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    color: rgb(52, 71, 103);
    font-weight: 600;
}
.upsidesection{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.comparesection{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding-bottom: 40px;
}

.withTroopod{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
}
.withoutTroopod{
    width: 30%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.withTroopod , .withoutTroopod  h4{
    font-size: 20px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.0075em;
    opacity: 1;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    color: rgb(52, 71, 103);
    font-weight: 600;
}